import React, { useState }from 'react';
import Marquee from 'react-fast-marquee';
import './App.css';
import Footer from './footer/footer';


function App() {
      //  Counter is a state initialized to 0
      const [counter, setCounter] = useState(0);

      
    // Function is called everytime increment button is clicked
    const handleClick1 = () => {
      // Counter state is incremented
      setCounter(counter + 1);
  };


      // Function to play the WAV sound
      const playSound = () => {
        const audio = new Audio('/sounds/quack.mp3'); // Path to your WAV sound file
        audio.play();
        handleClick1();
    };


  return (
    <div className="bg-yellowish font-first min-h-screen w-full bg-cover bg-center sm:bg-top flex flex-col justify-between items-center pt-4 sm:pt-8">


      {/* Header */}
      <header className="w-full flex justify-center items-center">
        <h1 className="text-4xl sm:text-6xl text-orange">DUCK YOU</h1>
      </header>

      {/* Fixed Image on Right Side */}
      <img src="/images/duck.png" alt="duck" className="fixed-duck-right" />
      {/* Fixed Image on Right Side */}
      <img src="/images/duck.png" alt="duck" className="fixed-duck-left" />



      {/* Contract Address */}
      <div className="w-full flex justify-center items-center">
        <p className="text-green text-xs sm:text-4xl mt-4">CA: EnDHPY6vcbTrnUBGeoqHokvcVJLHvY7VP1EaPE33pump</p>
      </div>

      {/* Buy Button */}
      <div className="w-full flex justify-center items-center">
      <button className="bg-white border-2 rounded-full w-20 h-20 flex justify-center items-center text-black text-sm sm:text-sm mr-2 mt-12"
      onClick={playSound}>
          QUACK
        </button>
      <a href="https://raydium.io/swap/?inputMint=EnDHPY6vcbTrnUBGeoqHokvcVJLHvY7VP1EaPE33pump&outputMint=sol" target="_blank" rel="noopener noreferrer">
        <button className="bg-white border-2 rounded-full w-60 h-20 flex justify-center items-center text-black text-4xl sm:text-4xl mt-12">
          BUY DUCK
        </button>
      </a>
      <button className="bg-white border-2 rounded-full w-20 h-20 flex justify-center items-center text-black text-sm sm:text-sm ml-2 mt-12" onClick={playSound}>
      QUACK
        </button>
        </div>

        <div className="w-full flex justify-center items-center mt-2">
          <p >How many quacks?</p>
          </div>
          <div className="w-full flex justify-center items-center mt-2">
          <p >{counter}</p>
          </div>


      {/* Marquee Text */}
      <Marquee gradient={false} speed={80} className='mt-20'>
        {/* Marquee content */}
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        {/* Repeat as needed */}
      </Marquee>

      <Marquee gradient={false} speed={100} direction='right'>
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        <img src="/images/duck.png" alt="duck" className="w-16 h-16 sm:w-48 sm:h-48" />
        {/* Repeat image as needed */}
      </Marquee>

            {/* Marquee Text */}
            <Marquee gradient={false} speed={80} >
        {/* Marquee content */}
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-green text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        <div className="text-white text-2xl sm:text-4xl mt-4 ml-10">DUCK YOU</div>
        {/* Repeat as needed */}
      </Marquee>

      {/* Footer */}
      <div className="mt-auto w-full flex justify-center items-center pb-4">
        <Footer />
      </div>

    </div>
  );
}

export default App;
