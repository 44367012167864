import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import './footer.css';

function Footer() {
  return (
    <div className="flex justify-center items-center h-16 bg-transparent mt-3">
      {/* Social icons */}
      <div className="flex space-x-4">
        {/* <a href="https://dexscreener.com/solana/esmxyvp1buveuswxr13jd8dacxo7csksepasv2vnkmrb" className="w-12 h-12 flex justify-center rounded-full items-center bg-white bording-black">
          <img
            src="/images/dex.png" // Or the direct URL to the image
            alt="dex"
            className="w-10 h-10"
          />
        </a> */}

        <a href="https://x.com/Whattheducksol" className="w-12 h-12 rounded-full flex justify-center items-center bg-black bording-black">
          <FontAwesomeIcon icon={faX} className="text-white w-5 h-5" />
        </a>
        <a href="https://t.me/duckYouSolana" className="w-12 h-12 rounded-full flex justify-center items-center bg-blue-500 bording-black">
          <FontAwesomeIcon icon={faTelegram} className="text-white w-5 h-5" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
